import React from "react"
import { FaCode, FaSketch, FaAlignLeft, FaServer, FaRegComments, FaPaintBrush, FaTrophy } from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "Architecture & Design Patterns",
    text: `MVC, Redux, Active Records, RESTful API, JWT, GRAPHQL`,
  },
  {
    id: 2,
    icon: <FaAlignLeft className="service-icon" />,
    title: "Programming Language",
    text: `Ruby, JavaScript, Dart, SQL, HTML, CSS.`,
  },
  {
    id: 3,
    icon: <FaSketch className="service-icon" />,
    title: "Tools, Frameworks, Libraries",
    text: `React.js, Redux, Rails, Sinatra, Ruby-on Rails, Flutter, Bootstrap, Grommet.io, Bulma, UIKit, Strapi.io, Gatsby`,
  },
  {
    id: 4,
    icon: <FaPaintBrush className="service-icon" />,
    title: "Design",
    text: `Adobe Photoshop, Figma, Lucid Charts`,
  },
  {
    id: 5,
    icon: <FaServer className="service-icon" />,
    title: "RDBMs",
    text: `PostgreSQL, SQLite3`,
  },
  {
    id: 6,
    icon: <FaRegComments className="service-icon" />,
    title: "Foreign Language",
    text: `Spanish`,
  },
  {
    id: 7,
    icon: <FaTrophy className="service-icon" />,
    title: "Hackathons",
    text: `Brothers Hackathon 2020`
  }
]
