import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import SocialLinks from "../constants/socialLinks"
import { FaFileAlt, FaEnvelope } from "react-icons/fa"
const query = graphql`
  {
    file(relativePath: {eq: "hero-img.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `

const Hero = () => {
  const {
    file: {
      childImageSharp: {fluid},
    },
  } = useStaticQuery(query)
   
  return (
    <header className="hero">
      <div className="section-center hero-center">
        <article className="hero-info">
          <div>
            <h1> I'm Francisco</h1>
            <h4>Full-stack Web And Mobile Developer.</h4>
            <Link to='/contact' className='btn'>
              Hit me Up! <FaEnvelope></FaEnvelope>
            </Link>
            
            <a href="https://learn.co/FranciscoAndaur/resume" target="_blank" className='btn'>
            resume <FaFileAlt></FaFileAlt> 
            </a>
            <SocialLinks />
          </div>
        </article>
        <Image fluid={fluid} className="hero-img"/>
      </div>
    </header>
  )
}

export default Hero
